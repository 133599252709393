






import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class SpinnerComponent extends Vue {
  @Prop() private container: any;
  @Prop() private top: any;

  private getSpinnerClass(): string {
    return (this.container as boolean) ? 'spinner-container' : 'spinner-full-screen';
  }

  private getSpinnerStyle(): string {
    return this.top ? ('top:' + this.top + '%') : '';
  }
}
