






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatCurrency } from '../lib/shared';
@Component
export default class FormattedTextBoxComponent extends Vue {
  @Prop() private value: any;
  @Prop() private rules: any;
  @Prop() private type: any;
  @Prop() private cssClass: any;
  @Prop() private disabled: any;
  @Prop() private label: any;
  @Prop() private zeroValid: any;
  @Prop() private negativeValid: any;

  private inputMode: boolean = false;
  private displayModel: string = '';
  private underlyingModel: any = '';

  private mounted() {
    if (this.value !== null) {
      this.underlyingModel = this.value;
      this.performFormatting();
    } else {
      this.inputMode = true;
    }
  }

  private performFormatting(): void {
    let isValid = false;
    switch (this.type) {
      case 'currency':
        const formatted = formatCurrency(this.underlyingModel);
        if (isNaN(Number(formatted))
        || (this.zeroValid === 'true'
            ? this.negativeValid === 'true' ? false : Number(formatted) < 0
            : this.negativeValid === 'true' ? Number(formatted) === 0 : Number(formatted) <= 0)) {
          this.displayModel = this.underlyingModel;
        } else {
          isValid = true;
          this.underlyingModel = formatted;
          this.displayModel = (this as any).$options.filters.currency(this.underlyingModel);
        }
        this.$emit('input', Number(this.underlyingModel));
        break;
    }
    this.inputMode = !isValid;
  }
}
