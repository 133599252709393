
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatDateForDisplay } from '../lib/datetime';
@Component
export default class DatePickerComponent extends Vue {
  @Prop() public value: any;
  @Prop() public label: any;
  @Prop() public rules: any;
  @Prop() public disabled: any;
  @Prop() public required: any;
  @Prop() public min: any;
  @Prop() public clearable: any;

  private showMenu: boolean = false;
  private underlyingModel: any = '';
  private displayModel: string = '';

  private mounted() {
    if (this.value) {
      this.underlyingModel = this.value;
      this.formatDate();
    }
  }

  private formatDate() {
    this.displayModel = formatDateForDisplay(this.underlyingModel);
  }

  private changeDate() {
    this.formatDate();
    this.$emit('input', this.underlyingModel);
    this.showMenu = false;
  }

  private clearDate() {
    this.underlyingModel = '';
    this.$emit('input', this.underlyingModel);
  }
}
