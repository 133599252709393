









































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ValidationSummaryComponent from '../components/ValidationSummaryComponent.vue';
import FormattedTextBoxComponent from '../components/FormattedTextBoxComponent.vue';
import DatePickerComponent from '../components/DatePickerComponent.vue';
import { getShortList, getReferenceData, getExpense, updateExpense, createExpense, getProject } from '../store/rest.service';
import { Project, ProjectMeta, ReferenceData, CommonMeta, SortColumnMapping, CurrentUser, Expense, ExpenseMeta, RevenueMeta, PaymentMeta } from '../store/models';
import { EmptyProject, EmptyExpense } from '../store/models-empty';
import * as datetime from '../lib/datetime';
import * as _ from 'lodash';

@Component({
  components: {
    ValidationSummaryComponent,
    FormattedTextBoxComponent,
    DatePickerComponent,
  },
})
export default class SpendComponent extends BaseComponent {

  @Prop() private spendId: any;
  @Prop() private projectId: any;
  @Prop() private spendType: any;

  private currencies: ReferenceData|null = null;
  private currenciesLoading: boolean = false;

  private editedProjectId: string = '';
  private editedSpendId: string = '';
  private editedProjectItem: Project = _.cloneDeep(EmptyProject);
  private editedSpendItem: Expense = _.cloneDeep(EmptyExpense);

  private changeOrderLoading: boolean = false;
  private changeOrderDeleting: boolean = false;
  private changeOrderDeleteResponse: any = null;
  private changeOrderSaving: boolean = false;
  private changeOrderSaveResponse: any;

  private projectLoading: boolean = false;
  private updateValidation: number = 0;

  protected mounted() {
    if (this.spendId !== '') {
      this.editSpend(this.spendId);
    } else {
      this.newSpend();
    }
  }

  private updateEditedProject(id: string) {
    this.editedProjectId = id;
    this.getProject(this.editedProjectId);
  }

  private updateEditedSpend(id: string) {
    this.editedSpendId = id;
    this.getSpend(this.editedSpendId);
  }

  private editSpend(id: string) {
    this.updateEditedSpend(id);
  }

  /*
  private deleteSpend() {
    if (confirm('Are you sure you want to delete this Change Order?')) {
      if (this.editedSpendId) {
        this.changeOrderDeleting = true;
        deleteSpend(this.editedSpendId)
        .then((response) => {
          this.changeOrderDeleteResponse = response;
        }).catch((error) => {
          this.$emit('error', error);
        }).finally(() => {
          this.changeOrderDeleting = false;
          this.closeSpend();
        });
      }
    }
  }
  */

  private newSpend() {
    this.getProject(this.projectId);
  }

  private initSpend() {
    this.editedSpendId = '';
    this.editedSpendItem = _.cloneDeep(EmptyExpense);

    // Correctly initalize PO or CO Spend.
    const expense = (this.editedSpendItem.expense as ExpenseMeta);
    expense.expenseClass = 'services'; // Is this correct?
    expense.expenseType = 'project-expense';
    expense.expenseKind = this.spendType;

    const revenue = (this.editedSpendItem.revenue as RevenueMeta);
    revenue.revenueClass = 'services'; // Is this correct?
    revenue.revenueType = 'project-revenue';
    revenue.revenueKind = this.spendType;

    const payment = (this.editedSpendItem.payment as PaymentMeta);
    payment.hasPayer = this.editedProjectItem.project.creatorVendor as string;
    payment.hasPayee = this.currentOrg;
    payment.hasProject = (this.editedProjectItem.common as CommonMeta).identifier;
    payment.hasSubmitter = this.currentUser.userinfo.identifier;
    payment.effectiveDate = datetime.getCurrentDate();
  }

  private closeSpend() {
    this.$emit('close');
  }

  private saveSpend() {
    if (!this.changeOrderSaving) {
      if ((this.$refs.form as any).validate()) {
        this.updateValidation++;
        this.editedSpendId === '' ? this.createSpend() : this.updateSpend();
      } else {
        this.updateValidation++;
      }
    }
  }

  private getProject(id: string) {
    this.projectLoading = true;
    getProject(id, false).then((response) => {
      this.editedProjectItem = response;
      this.initSpend();
    }).catch((error) => {
      this.$emit('error', error);
      this.projectLoading = false;
    }).finally(() => {
      this.projectLoading = false;
    });
  }

  private getSpend(id: string) {
    this.changeOrderLoading = true;
    getExpense(id).then((response) => {
      this.editedSpendItem = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.changeOrderLoading = false;
    });
  }

  private createSpend() {
    this.changeOrderSaving = true;
    createExpense(this.editedSpendItem).then((response) => {
      this.changeOrderSaveResponse = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.$emit('save');
      this.changeOrderSaving = false;
      this.closeSpend();
    });
  }

  private updateSpend() {
    this.changeOrderSaving = true;
    updateExpense(this.editedSpendId, this.removeCommonProperties(this.editedSpendItem)).then((response) => {
      this.changeOrderSaveResponse = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.$emit('save');
      this.changeOrderSaving = false;
      this.closeSpend();
    });
  }
}
