
















































































import { Watch, Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import { BenefitCategory } from '../components/BenefitHelper';
import { getProject } from '../store/rest.service';
import { Project, GeneralSearchCriteria } from '../store/models';
import { EmptyProject, EmptyGeneralSearchCriteria } from '../store/models-empty';
import ErrorComponent from '../components/ErrorComponent.vue';
import ProjectComponent from '../components/ProjectComponent.vue';
import ProjectDetailsComponent from '../components/ProjectDetailsComponent.vue';
import SpendComponent from '../components/SpendComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import { convertToPercent } from '../lib/currency';
import { handleError } from '../lib/shared';
import * as _ from 'lodash';

@Component({
  components: {
    ErrorComponent,
    ProjectComponent,
    ProjectDetailsComponent,
    SpendComponent,
    DialogComponent,
  },
})
export default class ProjectSummary extends BaseComponent {

  protected baseRoute: string = '/projects';

  private convertToPercent = convertToPercent;

  private bInitialLoad: boolean = true;
  private editedId: string = '';
  private editedChangeOrderId: string = '';
  private editedPurchaseOrderId: string = '';

  private updateProject: number = 0;
  private updateSubProject: number = 0;

  private bEditProject: boolean = false;
  private bEditChangeOrder: boolean = false;
  private bEditPurchaseOrder: boolean = false;
  private arrErrors: Error[] = [];
  private projectBenefitCategory = BenefitCategory.ProjectBenefit;
  private searchCriteria: GeneralSearchCriteria =  _.cloneDeep(EmptyGeneralSearchCriteria);

  protected mounted() {
    this.mount();
  }

  private mount() {
    if (this.$route.params.id) {
      this.editedId = this.$route.params.id;
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(val: any, oldVal: any) {
    if (!_.isEqual(val, oldVal)) {
      this.mount();
    }
  }

  private getKey(label: string) {
    return this.editedId + this.updateProject + label;
  }

  private formTitle() {
    return 'Project Summary';
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  private refreshProject() {
    this.mount();
    this.updateProject++;
    this.$emit('refresh');
  }

  private closeEditProject() {
    this.bEditProject = false;
  }

  private editChangeOrder(id) {
    this.editedChangeOrderId = id;
    this.bEditChangeOrder = true;
  }

  private newChangeOrder() {
    this.editedChangeOrderId = '';
    this.bEditChangeOrder = true;
  }

  private closeEditChangeOrder() {
    this.bEditChangeOrder = false;
  }

  private editPurchaseOrder(id) {
    this.editedPurchaseOrderId = id;
    this.bEditPurchaseOrder = true;
  }

  private closeEditPurchaseOrder() {
    this.bEditPurchaseOrder = false;
  }
}
