


























































































































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ChangeOrderSummaryComponent from '../components/ChangeOrderSummaryComponent.vue';
import { EmptyProject } from '../store/models-empty';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { getProject, getReferenceData } from '../store/rest.service';
import { Project, ReferenceData } from '../store/models';
import { getRefTypeNameandCategoryFromCode } from '../lib/shared';
import * as _ from 'lodash';

@Component({
  components: {
    ChangeOrderSummaryComponent,
    SpinnerComponent,
  },
})
export default class ProjectDetailsComponent extends BaseComponent {

  @Prop() private id: any;

  private projectLoading: boolean = false;
  private editedProjectItem?: Project|null = null;

  private contractingTypes: ReferenceData|null = null;
  private contractingTypesLoading: boolean = false;

  private getRefTypeNameandCategoryFromCode = getRefTypeNameandCategoryFromCode;

  protected mounted() {
    this.getContractingTypeReferenceData();
    this.getProject(this.id);
  }

  private editProject() {
    this.$emit('edit-project');
  }

  private editChangeOrder(id: string) {
    this.$emit('edit-change-order', id);
  }

  private newChangeOrder() {
    this.$emit('new-change-order');
  }

  private editPurchaseOrder(id: string) {
    this.$emit('edit-purchase-order', id);
  }

  private deleteChangeOrder() {
    this.$emit('delete-change-order');
  }

  private getProject(id: string) {
    this.projectLoading = true;
    getProject(id, true).then((response) => {
      this.editedProjectItem = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.projectLoading = false;
    });
  }

  private getContractingTypeReferenceData() {
    if (!this.contractingTypes) {
      this.contractingTypesLoading = true;
      getReferenceData('contracting-types')
      .then((response) => {
        this.contractingTypes = response;
      }).catch((error) => {
        this.$emit('error', error);
      }).finally(() => {
        this.contractingTypesLoading = false;
      });
    }
  }
}
