







































































































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import DeleteModalComponent from './DeleteModalComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import { searchChangeOrders, searchPurchaseOrders, deleteExpense } from '../store/rest.service';
import { ResourceSearchResult, SortColumnMapping, Expense } from '../store/models';
import { getIconUrl } from '../lib/images';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import * as _ from 'lodash';

@Component({
  components: {
    DeleteModalComponent,
    SpinnerComponent,
    DialogComponent,
  },
})
export default class ChangeOrderSummaryComponent extends BaseComponent {

  @Prop() private projectId: any;

  private purchaseOrder: Expense|null = null;
  private changeOrders: ResourceSearchResult|null = null;
  private changeOrdersLoading: boolean = false;
  private purchaseOrderLoading: boolean = false;
  private changeOrderPagination: any = { sortBy: 'spend.spendId', descending: false, page: 1, rowsPerPage: 3 };

  private bDeleteChangeOrderModal: boolean = false;
  private deleteChangeOrderId: string = '';
  private deleteChangeOrderEtag: string = '';

  private sortMappings: SortColumnMapping[] = [
    {
      field: 'spend.spendId',
      sortColumn: 'default',
    },
  ];

  protected mounted() {
    this.mount();
  }

  private editChangeOrder(id: string) {
    this.$emit('edit-change-order', id);
  }

  private newChangeOrder() {
    this.$emit('new-change-order');
  }

  private editPurchaseOrder(id: string) {
    this.$emit('edit-purchase-order', id);
  }

  private navChangeOrders() {
    this.$router.push({ path: '/project/id/' + this.projectId + '/changeorder' });
  }

  private getEditIcon(): string {
    return getIconUrl('icon_edit.png');
  }

  private getDeleteIcon(): string {
    return getIconUrl('icon_delete.png');
  }

  private getProjectChangeOrders(projectId: string) {
    this.changeOrdersLoading = true;
    searchChangeOrders(this.mapSearchParams(this.changeOrderPagination, '', this.sortMappings), { project: projectId}).then((response) => {
      this.changeOrders = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.changeOrdersLoading = false;
    });
  }

  private getProjectPurchaseOrder(projectId: string) {
    this.purchaseOrderLoading = true;
    searchPurchaseOrders(this.mapSearchParams({ sortBy: 'default', descending: false, page: 1, rowsPerPage: 1 }, '', []), { project: projectId}).then((response) => {
      this.purchaseOrder = ((response as ResourceSearchResult).searchResults.results[0] as Expense);
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.purchaseOrderLoading = false;
    });
  }

  private deleteChangeOrder(id: string, etag: string) {
    this.deleteChangeOrderId = id;
    this.deleteChangeOrderEtag = etag;
    this.bDeleteChangeOrderModal = true;
  }

  private mount() {
    this.getProjectChangeOrders(this.projectId);
    this.getProjectPurchaseOrder(this.projectId);
  }

  private closeDeleteChangeOrder() {
    this.bDeleteChangeOrderModal = false;
    this.$emit('delete');
    this.mount();
  }

  private performDeleteChangeOrder() {
    deleteExpense(this.deleteChangeOrderId, this.deleteChangeOrderEtag)
    .then((response) => {
      this.closeDeleteChangeOrder();
    }).catch((error) => {
      this.$emit('error', error);
      this.closeDeleteChangeOrder();
    });
  }
}
