import { getShortList, getReferenceData, getVendorBeneficiaryRepsShortList, getVendorShortList, getTieredReferenceData, searchAggregatesByRelation } from '../store/rest.service';
import { ResourceSearchResult, ReferenceData, ShortRecord, TieredReferenceData, Aggregate, TotalsMeta } from '../store/models';
import { handleError, getInvestmentCategoryForDisplay } from '../lib/shared';
import { EmptyGeneralSearchCriteria } from '../store/models-empty';
import * as _ from 'lodash';

export enum BenefitCategory {
  ProjectBenefit,
  CommunityInvestment,
}

export default class BenefitHelper {

  public benefitCategory: BenefitCategory;

  public arrErrors: Error[] = [];

  public ics: ShortRecord[]|null = null;
  public icsLoading: boolean = false;

  public orgIcs: ResourceSearchResult|null = null;
  public orgIcsWithCommunityInvestment: ShortRecord[]|null = null;
  public orgIcsLoading: boolean = false;

  public projects: ShortRecord[]|null = null;
  public projectsLoading: boolean = false;

  public communityOrgs: ShortRecord[]|null = null;
  public communityOrgsLoading: boolean = false;

  public spendTypes: ReferenceData|null = null;
  public spendTypesLoading: boolean = false;

  public investmentTypes: TieredReferenceData|null = null;
  public investmentTypesLoading: boolean = false;
  public investmentTypesConverted: any = [];

  public statusTypes: ReferenceData|null = null;
  public statusTypesLoading: boolean = false;

  public currencies: ReferenceData|null = null;
  public currenciesLoading: boolean = false;

  public getInvestmentCategoryForDisplay = getInvestmentCategoryForDisplay;

  public isLoading: boolean = true;

  constructor(benefitCategory: BenefitCategory) {
    this.benefitCategory = benefitCategory;
  }

  public setIsLoading(): void {
    this.isLoading = (
      this.spendTypesLoading
      || this.currenciesLoading
      || this.icsLoading
      || this.projectsLoading
    );
  }

  public populateDataLists(currentOrg: string) {
    this.getProjects(currentOrg);
    this.getOrgCommunities(currentOrg); // TODO: Is this required anymore?
    this.getSpendTypes();
    this.getStatusTypes();
    this.getBenefitInvestmentTypes();
    this.getCurrencyReferenceData();
    this.getIndigenousCommunities();
  }

  public getCurrencyReferenceData() {
    if (!this.currencies) {
      this.currenciesLoading = true;
      getReferenceData('currencies')
      .then((response) => {
        this.currencies = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.currenciesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getBenefitInvestmentTypes() {
    if (!this.spendTypes) {
      this.spendTypesLoading = true;
      getTieredReferenceData('community-investment-categories')
      .then((response) => {
        this.investmentTypes = response;
        // Convert the spend types to a format required by the Treeselect control.
        for (const category of this.investmentTypes.refData.values) {
          const children: any = [];
          for (const benefitArea of category.subCategories) {
            const benefitAreaItem = {
              id: category.code + ';' + benefitArea.code,
              label: benefitArea.name,
              category: benefitArea.category,
            };
            children.push(benefitAreaItem);
          }
          const categoryItem = {
            id: category.code,
            label: category.name,
            children,
          };
          this.investmentTypesConverted.push(categoryItem);
        }
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.spendTypesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getSpendTypes() {
    this.getBenefitCategory() === BenefitCategory.ProjectBenefit ?  this.getSpendTypesProjectBenefits() : this.getSpendTypesCommunityInvestments();
  }

  public getSpendTypesProjectBenefits() {
    if (!this.spendTypes) {
      this.spendTypesLoading = true;
      getReferenceData('project-benefit-types')
      .then((response) => {
        this.spendTypes = response;
        getReferenceData('community-investment-types')
        .then((response2) => {
          for (const i of (response2 as ReferenceData).refData.values) {
            const items = (this.spendTypes as ReferenceData).refData.values;
            if (!items.filter((item) => item.code === i.code)) {
              (this.spendTypes as ReferenceData).refData.values.push(i);
            }
          }
        }).catch((error) => {
          handleError(this.arrErrors, error);
      });
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.spendTypesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getSpendTypesCommunityInvestments() {
    if (!this.spendTypes) {
      this.spendTypesLoading = true;
      getReferenceData('community-investment-types')
      .then((response) => {
        this.spendTypes = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.spendTypesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getIndigenousCommunities() {
    if (!this.ics) {
      this.icsLoading = true;
      getShortList('IndigenousCommunity', false, [], true)
      .then((response) => {
        this.ics = response.searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.icsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getOrgCommunities(currentOrg: string) {
    this.orgIcsLoading = true;
    const searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    searchAggregatesByRelation(searchCriteria, 'relatedic', currentOrg)
    .then((response) => {
      this.orgIcs = response;
      this.orgIcsWithCommunityInvestment = this.filterOrgIcsWithCommunityInvestment(this.orgIcs);
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.orgIcsLoading = false;
      this.setIsLoading();
    });
  }

  public filterOrgIcsWithCommunityInvestment(orgIcs: ResourceSearchResult): ShortRecord[] {
    const filteredResults: ShortRecord[] = [];
    for (const result of orgIcs.searchResults.results) {
      const aggregate: Aggregate = (result as Aggregate);
      //if ((aggregate.totals as TotalsMeta).benefit.communityInvestment.submitted > 0) {
      if ((aggregate.totals as any).benefit.communityInvestment.submitted > 0) {
        filteredResults.push({ displayName: aggregate.facetDetail.displayName, identifier: aggregate.facetDetail.identifier });
      }
    }
    return filteredResults.sort((a, b) => ((a.displayName as string) < (b.displayName as string) ? -1 : 1));
  }

  public getProjects(currentOrg: string, force: boolean = false) {
    if (!this.projects || force) {
      this.projectsLoading = true;
      const whereClauses: Array<[string, string[]]> = [ [ 'project.ownerVendor', [ currentOrg ] ] ];
      getShortList('Project', false, whereClauses, false, 'poId')
      .then((response) => {
        this.projects = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.projectsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getStatusTypes() {
    if (!this.statusTypes) {
      this.statusTypesLoading = true;
      getReferenceData('spend-states')
      .then((response) => {
        this.statusTypes = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.statusTypesLoading = false;
        this.setIsLoading();
      });
    }
  }

  // Filtered based on the selected Indigenous Community, also add 'Direct to Community' to the list.
  public getCommunityOrganizations(id: string) {
    if (!this.communityOrgs) {
      this.communityOrgsLoading = true;
      //If this is a vendor on an edited record then populate the list with that vendor, we do not know the Indigenous Community anymore.
      if (id.startsWith('urn:nisto-link:id:vendor:')) {
        getVendorShortList(id)
        .then((response) => {
          this.communityOrgs = response.searchResults.results as ShortRecord[];
        }).catch((error) => {
          handleError(this.arrErrors, error);
        }).finally(() => {
          this.communityOrgsLoading = false;
          this.setIsLoading();
        });
      } else {
        getVendorBeneficiaryRepsShortList(id)
        .then((response) => {
          this.communityOrgs = response.searchResults.results as ShortRecord[];
          const directToCommunityResult: ShortRecord = { identifier: 'direct', displayName: 'Direct to Community' };
          this.communityOrgs.push(directToCommunityResult);
        }).catch((error) => {
          handleError(this.arrErrors, error);
        }).finally(() => {
          this.communityOrgsLoading = false;
          this.setIsLoading();
        });
      }
    }
  }

  public getBenefitCategory(): BenefitCategory {
    return this.benefitCategory;
    // this.$route.path.startsWith('/project-benefit') ? BenefitCategory.ProjectBenefit : BenefitCategory.CommunityInvestment;
  }

  public getBenefitCategoryString(): string {
    return this.benefitCategory === BenefitCategory.ProjectBenefit ? 'project-benefit' : 'community-investment';
  }

  public getBenefitCategoryDescription(): string {
    return this.benefitCategory === BenefitCategory.ProjectBenefit ? 'Project Benefit' : 'Community Contribution';
  }

  public isProjectBenefits(): boolean {
    return this.benefitCategory === BenefitCategory.ProjectBenefit;
  }

  public handleError(error: any) {
    handleError(this.arrErrors, error);
  }
}
